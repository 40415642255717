import React from "react";

const CalltoActionSection = () => {
  return (
    <div className="subscribe-section bg-with-black">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="subscribe-head">
              <h2>This is a demo site.</h2>
              <p>After looking this demo, you can order your own shopping cart and load your products.</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalltoActionSection;
